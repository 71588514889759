import { FC, useCallback, useEffect, useState } from "react"
import cx from "classnames"
import SearchIcon from "@/assets/search.svg"
import TextField from "@/components/TextField"
import IconButton from "@/components/IconButton"
import FilterIcon from "@/components/icons/FilterIcon"
import InboxList from "./features/InboxList"
import Menu from "@/components/Menu"
import MoreIcon from "@/components/icons/MoreIcon"
import DeleteIcon from "@/components/icons/DeleteIcon"
import InboxFiltersDrawer from "./features/InboxFiltersDrawer"
import SelectedInboxView from "./features/SelectedInboxView"
import DeleteInboxModal from "./features/DeleteInboxModal"
import Tooltip from "@/components/Tooltip"
import InboxIcon from "@/assets/inbox.svg"
import SelectionEmpty from "@/components/layouts/SelectionEmpty"
import Loader from "@/components/Loader"
import AttachmentsIcon from "@/components/icons/AttachmentsIcon"
import MimeTypeIcon from "@/components/MimeTypeIcon"
import { usePageTitle } from "@/hooks/usePageTitle"
import { useDownloadFile } from "@/hooks/useDownloadFile"
import {
  InboxFiltersContext,
  getInitInboxFilters,
  getInitInboxSort,
} from "./context"
import { sortInboxes } from "./features/InboxList/utils"
import { useInboxes } from "./hooks/useInboxes"
import { usePagination } from "@/hooks/usePagination"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useUpdateInboxMutation } from "@/redux/services/inboxApi"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import type { SortOptions } from "@/types/shared"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
}

const InboxPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Inbox")
  const { pageFullAccess } = useUserAccess({ pageName: "Inbox" })
  const [filters, setFilters] = useState<Record<string, any>>(
    getInitInboxFilters(),
  )
  const { md } = useBreakpoints()
  const [sort, setSort] = useState<SortOptions>(getInitInboxSort())
  const [skip, setSkip] = useState(0)
  const { items: inboxes, isLoading: inboxesLoading } = useInboxes()
  const { searchTerm, handleSearchTermChange } = usePagination()
  const [selectedInboxId, setSelectedInboxId] = useState("")
  const [openFilters, setOpenFilters] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [updateInbox] = useUpdateInboxMutation()

  const handleInboxSelect = useCallback(
    async (inbox: Inbox) => {
      if (!inbox.readAt) {
        await updateInbox({ id: [inbox._id], read: true })
      }
      setSelectedInboxId(inbox._id!)
    },
    [updateInbox],
  )
  const downloadFile = useDownloadFile()

  useEffect(() => {
    if (!md) {
      return
    }
    const sortedInboxes = [...inboxes].sort((a, b) => sortInboxes(a, b, sort))
    let lastUpdatedInboxId = ""
    if (sortedInboxes.find(({ _id }) => selectedInboxId === _id)) {
      return
    }
    if (sortedInboxes.length > 0) {
      lastUpdatedInboxId = sortedInboxes[0]._id
      if (!sortedInboxes[0].readAt) {
        handleInboxSelect(sortedInboxes[0])
      }
    }
    setSelectedInboxId(lastUpdatedInboxId)
  }, [inboxes, handleInboxSelect, sort, selectedInboxId, md])

  const selectedInbox = inboxes.find(({ _id }) => _id === selectedInboxId)

  return (
    <InboxFiltersContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        skip,
        setSkip,
      }}
    >
      <div
        className={cx(
          "bg-white min-h-full block-shadow rounded-md flex flex-col md:flex-row md:h-full",
          { "h-full": inboxesLoading },
          className,
        )}
        {...rest}
      >
        <div
          className={cx(
            "flex flex-col h-full md:max-w-[220px] lg:max-w-[329px] flex-grow border-r border-outline",
            {
              "flex-grow-0": selectedInboxId,
            },
          )}
        >
          <div
            className={cx("flex gap-4 p-3 border-b border-outline h-15", {
              "hidden md:flex": selectedInboxId,
            })}
          >
            <TextField
              startIcon={<SearchIcon />}
              className="input-base text-sm"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <Tooltip title="Filters">
              <IconButton onClick={() => setOpenFilters(true)}>
                <div className="relative">
                  {Object.values(filters).reduce(
                    (acc, value) => (value?.length > 0 ? acc + 1 : acc),
                    0,
                  ) > 0 && (
                    <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-primary rounded-full border-[1.5px] border-white"></div>
                  )}
                  <FilterIcon className="w-6 h-6" />
                </div>
              </IconButton>
            </Tooltip>
          </div>
          {inboxesLoading ? (
            <div className="h-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <InboxList
              className={cx("flex-1", {
                "hidden md:flex": selectedInboxId,
              })}
              selectedInboxId={selectedInboxId}
              inboxes={inboxes}
              onInboxSelect={handleInboxSelect}
            />
          )}
        </div>
        {!inboxesLoading && (
          <>
            {selectedInboxId && selectedInbox ? (
              <div
                key={selectedInboxId}
                className="flex flex-col flex-1 md:max-w-[calc(100%-220px)] lg:max-w-[calc(100%-329px)]"
              >
                <div className="px-2 md:px-6 gap-1 py-3.5 flex justify-between h-15 border-b border-outline">
                  <div className="flex gap-1 items-center truncate">
                    <div
                      className={cx("md:hidden cursor-pointer", {
                        "self-center inline-block": selectedInboxId,
                        hidden: !selectedInboxId,
                      })}
                      onClick={() => setSelectedInboxId("")}
                    >
                      <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
                    </div>
                    <div className="text-lg font-semibold truncate">
                      {selectedInbox.subject}
                    </div>
                  </div>
                  <div className="flex gap-1 md:gap-3 items-center">
                    {selectedInbox.attachments.length ? (
                      <Menu
                        noCaret
                        items={selectedInbox.attachments.map(
                          ({ fileName, url, mimeType }) => (
                            <Tooltip title={`Press to open ${fileName}`}>
                              <div
                                onClick={() => downloadFile(url, fileName)}
                                className="inline-block text-sm leading-6 truncate max-w-full"
                              >
                                <div className="inline-block -translate-x-1 translate-y-1">
                                  <MimeTypeIcon mime={mimeType} />
                                </div>
                                <span className="leading-5">{fileName}</span>
                              </div>
                            </Tooltip>
                          ),
                        )}
                        className="[&_ul]:-right-10 sm:[&_ul]:right-4 [&_li]:max-w-full"
                      >
                        <IconButton>
                          <AttachmentsIcon />
                        </IconButton>
                      </Menu>
                    ) : null}
                    {pageFullAccess && (
                      <Menu
                        className="[&_ul]:right-4"
                        items={[
                          <span
                            onClick={() => setOpenDeleteModal(true)}
                            className="text-sm leading-6"
                          >
                            <DeleteIcon /> Delete Message
                          </span>,
                        ]}
                        noCaret
                      >
                        <MoreIcon className="opacity-60 hover:opacity-100 w-6 h-6" />
                      </Menu>
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-1 overflow-y-auto">
                  {selectedInbox && <SelectedInboxView inbox={selectedInbox} />}
                </div>
              </div>
            ) : (
              <SelectionEmpty
                message="Select a message"
                icon={<InboxIcon className="w-10 h-10" />}
                className="hidden md:flex"
              />
            )}
          </>
        )}
      </div>
      <InboxFiltersDrawer
        open={openFilters}
        onClose={() => setOpenFilters(false)}
      />
      <DeleteInboxModal
        data={selectedInbox ? [selectedInbox] : []}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={() => {
          if (!md) {
            setSelectedInboxId("")
          }
        }}
      />
    </InboxFiltersContext.Provider>
  )
}

export default InboxPage
