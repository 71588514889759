import { FC, useCallback, useEffect } from "react"
import cx from "classnames"
import Select from "@/components/Select"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { useEffectOnce } from "usehooks-ts"
import {
  selectCampaignSetupSelectedSender,
  selectCampaignSetupSenders,
  setSelectedSender,
  setSelectedSenders,
} from "@/redux/campaignSetupSlice"
import SenderCampaignViewForm from "./SenderCampaignViewForm"
import Checkbox from "@/components/Checkbox"
import type { SenderAPIResponse } from "@/types/entities/sender"
import type { DefaultOptionType } from "antd/es/select"
import type { EditCampaignPayload } from "@/types/entities/campaign"

type Props = {
  senders: SenderAPIResponse[]
  onChange: (senderIds: EditCampaignPayload) => void
}

const CampaignSenderView: FC<Props> = ({ senders, onChange }: Props) => {
  const dispatch = useAppDispatch()
  const selectedSenders = useAppSelector(selectCampaignSetupSenders)
  const selectedSender = useAppSelector(selectCampaignSetupSelectedSender)

  useEffect(() => {
    onChange({ sendersId: selectedSenders.map(({ _id }) => _id) })
  }, [onChange, selectedSenders])
  const handleSenderToggle = (checked: boolean, senderId: string) => {
    const sender = senders.find(({ _id }) => _id === senderId)!
    if (checked) {
      dispatch(setSelectedSenders(selectedSenders.concat(sender)))
      if (!selectedSender) {
        dispatch(setSelectedSender(sender))
      }
    } else {
      const newSenders = selectedSenders.filter(({ _id }) => _id !== sender._id)
      dispatch(setSelectedSenders(newSenders))
      if (
        !newSenders.find(({ _id }) => _id === selectedSender?._id) &&
        newSenders.length
      ) {
        dispatch(setSelectedSender(newSenders[0]))
      }
      if (!newSenders.length) {
        dispatch(setSelectedSender(null))
      }
    }
  }
  const handleSenderSelect = useCallback(
    (senderId: string) => {
      dispatch(setSelectedSender(senders.find(({ _id }) => _id === senderId)!))
      if (!selectedSenders.find(({ _id }) => _id === senderId)) {
        dispatch(
          setSelectedSenders(
            selectedSenders.concat(
              senders.find(({ _id }) => _id === senderId)!,
            ),
          ),
        )
      }
    },
    [dispatch, selectedSenders, senders],
  )
  useEffectOnce(() => {
    if (!selectedSender && senders.length === 1) {
      handleSenderSelect(senders[0]._id)
    }
  })
  const filterOption = (input: string, option: DefaultOptionType | undefined) =>
    ((option?.label ?? "") as string)
      .toLowerCase()
      .includes(input.toLowerCase())
  const value = selectedSender
    ? `${selectedSender?.firstName} ${selectedSender?.lastName}`
    : null

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg leading-7 font-semibold">Sender</h3>
      <div className="flex flex-col">
        <Select
          value={value}
          onChange={handleSenderSelect}
          optionRender={({ value, label }, { index }) => (
            <div
              onClick={(e) => e.stopPropagation()}
              className="flex items-center"
            >
              <Checkbox
                id={index.toString()}
                onChange={({ target: { checked } }) =>
                  handleSenderToggle(checked, value as string)
                }
                checked={
                  !!selectedSenders.find((sender) => sender._id === value)
                }
                className="checkbox-primary h-[18px] w-[18px]"
              />
              <div
                className="pl-2.5 flex-grow flex gap-2 items-center cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  handleSenderSelect(value as string)
                }}
              >
                <span className="text-sm leading-6">{label}</span>
              </div>
            </div>
          )}
          options={senders.map(({ firstName, lastName, _id }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
          }))}
          filterOption={filterOption}
          className="h-10 bg-input rounded-md"
          containerClassName="[&_.label-text]:text-xs [&_.label-text]:leading-normal"
          label="Sender Name"
          placeholder="Select sender"
          bordered={false}
          showSearch
        />
        <div
          className={cx("gap-5 mt-5", {
            "sm:grid sm:grid-cols-2 flex flex-col gap-3":
              selectedSenders.length > 1,
            "flex flex-col": selectedSenders.length < 1,
          })}
        >
          {selectedSenders.length ? (
            [...selectedSenders]
              .sort((a, b) => {
                const senderId = selectedSender?._id
                if (a._id === senderId) return -1
                if (b._id === senderId) return 1
                return 0
              })
              .map((selectedSender) => (
                <SenderCampaignViewForm
                  key={selectedSender._id}
                  className="bg-bg-default rounded-md px-4 py-2 border border-outline"
                  data={selectedSender}
                />
              ))
          ) : (
            <div className="my-10 text-center text-base leading-6 opacity-60">
              No sender selected.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignSenderView
