import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useGetInboxesQuery } from "@/redux/services/inboxApi"
import { useInboxFilters } from "./useInboxFilters"
import { POLLING_INTERVAL } from "@/constants"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { usePagination } from "@/hooks/usePagination"

export const INBOXES_LIMIT = 15000000

export const useInboxes = () => {
  const { id: org = "" } = useParams()
  const { filters, sort, skip, setSkip } = useInboxFilters()
  const { text } = usePagination()

  const {
    data: { total, items } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
    ...rest
  } = useGetInboxesQuery(
    {
      org,
      sort,
      skip,
      text,
      limit: INBOXES_LIMIT,
      campaign: filters.campaigns,
      classifications: filters.classifications,
    },
    { pollingInterval: POLLING_INTERVAL, refetchOnMountOrArgChange: true },
  )
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading || isFetching,
    hasNextPage: skip + INBOXES_LIMIT < total,
    onLoadMore: () => setSkip((skip) => skip + INBOXES_LIMIT),
    disabled: isError,
    rootMargin: "0px 0px 200px 0px",
  })
  useEffect(() => {
    setSkip(0)
  }, [filters, sort, total, org, text, setSkip])

  return {
    items,
    total,
    sentryRef,
    rootRef,
    isLoading,
    isFetching,
    isError,
    ...rest,
  }
}
