import { FC, useEffect, useMemo, useRef, useState } from "react"
import cx from "classnames"
import OutlineButton from "@/components/OutlineButton"
import CampaignsChart from "./features/CampaignsChart"
import CampaignChartTable from "./features/CampaignChartTable"
import ErrorMessage from "@/components/ErrorMessage"
import SingleGraphView from "./features/SingleGraphView"
import EmptyGraphView from "./features/EmptyGraphView"
import Loader from "@/components/Loader"
import IconButton from "@/components/IconButton"
import FilterIcon from "@/components/icons/FilterIcon"
import { useParams } from "react-router-dom"
import { usePageTitle } from "@/hooks/usePageTitle"
import { useDashboard } from "./hooks/useDashboard"
import {
  type DashboardPayload,
  type DashboardExportPayload,
  useGetDashboardReportingQuery,
} from "@/redux/services/organizationApi"
import { groupByCampaign, sortMultipleCampaigns } from "./utils"
import { POLLING_INTERVAL } from "@/constants"
import { formatDateToISOString } from "@/utils/dates"
import { useStatusOrganizations } from "@/hooks/useStatusOrganizations"
import MobileDashboardFiltersDrawer from "./features/MobileDashboardFiltersDrawer"
import DashboardFiltersRow from "./features/DashboardFiltersRow"
import DownloadIcon from "@/components/icons/DownloadIcon"
import { removeEmptyStrings } from "@/utils/objects"

const DashboardPage: FC = () => {
  const { id: orgId = "" } = useParams()
  const { all } = useStatusOrganizations()
  usePageTitle(() => all.find(({ _id }) => _id === orgId)?.name ?? "")

  const {
    campaigns,
    campaignsValue,
    grouping,
    handleExport,
    classificationsValue,
    selectedCampaigns,
    selectedClassifications,
    from,
    to,
    multiple,
    sender,
    emailAddress,
    sequence,
    email,
    version,
    masterList,
    indicatorsValue,
    isLoading: valuesLoading,
    exportLoading,
    isError,
  } = useDashboard()

  const [openMobileFilters, setOpenMobileFilters] = useState(false)
  const isPolling = useRef(false)
  const dashboardParams = useMemo<DashboardPayload>(
    () => ({
      id: orgId,
      group: grouping,
      multiple,
      campaigns: Object.keys(selectedCampaigns) ?? [],
      classifications: Object.keys(selectedClassifications) ?? [],
      from: formatDateToISOString(from),
      to: formatDateToISOString(to, "23:59:59.999"),
      sender,
      emailAddress,
      email,
      masterList,
      sequence,
      version,
    }),
    [
      from,
      grouping,
      multiple,
      orgId,
      selectedCampaigns,
      selectedClassifications,
      to,
      sender,
      emailAddress,
      email,
      version,
      masterList,
      sequence,
    ],
  )
  const exportParams = useMemo<DashboardExportPayload>(
    () => ({
      ...removeEmptyStrings(dashboardParams),
      campaigns: dashboardParams.campaigns?.length
        ? dashboardParams.campaigns
        : undefined,
      indicators: indicatorsValue.value?.length
        ? indicatorsValue.value?.map(({ label }) => label)
        : undefined,
    }),
    [dashboardParams, indicatorsValue.value],
  )
  const skip = useMemo<boolean>(
    () =>
      (Boolean(emailAddress) && !sender) ||
      (Boolean(version) && !email && !sender) ||
      (Boolean(email) && !sequence),
    [email, emailAddress, sender, sequence, version],
  )
  const {
    data: reporting,
    isLoading: reportingLoading,
    isFetching: reportingFetching,
    isError: reportingError,
    refetch,
  } = useGetDashboardReportingQuery(dashboardParams, {
    selectFromResult: (state) => {
      return {
        ...state,
        isFetching: isPolling.current ? false : state.isFetching,
      }
    },
    refetchOnMountOrArgChange: true,
    skip,
  })
  useEffect(() => {
    const intervalId = setInterval(async () => {
      isPolling.current = true
      await refetch()
      isPolling.current = false
    }, POLLING_INTERVAL)
    return () => {
      clearInterval(intervalId)
    }
  }, [refetch])

  const classificationLabels = classificationsValue.map(({ label }) => label)
  const indicatorLabels =
    indicatorsValue?.value?.map(({ label }) => label) ?? []
  const resultIndicators = classificationLabels.concat(indicatorLabels) ?? []

  if (isError || reportingError) {
    return <ErrorMessage />
  }

  const isLoading = valuesLoading || reportingLoading

  return (
    <>
      <div
        className={cx("flex flex-col gap-5 h-full", {
          "justify-center items-center": isLoading,
        })}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="flex xl:justify-between items-center flex-wrap gap-3">
              <IconButton
                className="flex xl:hidden"
                onClick={() => setOpenMobileFilters(true)}
              >
                <div className="relative">
                  <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-primary rounded-full border-[1.5px] border-white"></div>
                  <FilterIcon className="w-6 h-6" />
                </div>
              </IconButton>
              <DashboardFiltersRow className="hidden xl:flex" />
              <div className="flex gap-5">
                <OutlineButton
                  onClick={() => handleExport(exportParams)}
                  className="btn-base text-black"
                  loading={exportLoading}
                >
                  <DownloadIcon className="w-5 h-5 opacity-80" />
                  <span className="opacity-80">Export</span>
                </OutlineButton>
              </div>
            </div>
            {reportingFetching && !isPolling.current ? (
              <Loader className="flex-1 self-center" />
            ) : (
              <>
                {reporting?.emails.data.length ||
                reporting?.replies.data.length ? (
                  <>
                    {multiple === "true" &&
                    Object.entries(
                      groupByCampaign(
                        reporting.emails.data,
                        reporting.replies.data,
                      ),
                    ).length ? ( // checking this for case where 0 items in the array for multiple
                      <>
                        {Object.entries(
                          groupByCampaign(
                            reporting.emails.data,
                            reporting.replies.data,
                          ),
                        )
                          .sort((a, b) =>
                            sortMultipleCampaigns(a, b, campaigns),
                          )
                          .map(([campaignId, emails], i) => {
                            const emailsWithInitClassifications = emails.map(
                              (email) => {
                                return Object.assign(
                                  Object.keys(selectedClassifications)
                                    .map((c) => c.toLowerCase())
                                    .reduce(
                                      (acc, curr) =>
                                        Object.assign(acc, {
                                          [curr]: 0,
                                        }),
                                      {},
                                    ),
                                  { ...email },
                                )
                              },
                            )
                            return (
                              <div key={campaignId} className="space-y-4">
                                <h2 className="text-base font-semibold">
                                  {reporting.emails.totals.find(
                                    ({ _id }) => _id?.campaign === campaignId,
                                  )?.campaignName ?? campaignId}
                                </h2>
                                <CampaignsChart
                                  emails={emailsWithInitClassifications}
                                  totalData={
                                    reporting.emails.totals.find(
                                      ({ _id }) => _id?.campaign === campaignId,
                                    )!
                                  }
                                  totalReplyData={
                                    reporting.replies.totals.find(
                                      ({ _id }) => _id?.campaign === campaignId,
                                    )!
                                  }
                                  indicators={resultIndicators}
                                />
                                <CampaignChartTable
                                  emails={emailsWithInitClassifications}
                                />
                              </div>
                            )
                          })}
                      </>
                    ) : (
                      <SingleGraphView
                        label={campaignsValue.label}
                        indicators={resultIndicators}
                        reporting={reporting}
                      />
                    )}
                  </>
                ) : (
                  <EmptyGraphView indicators={resultIndicators} />
                )}
              </>
            )}
            <div className="py-3 w-full"></div>
          </>
        )}
      </div>
      <MobileDashboardFiltersDrawer
        open={openMobileFilters}
        onClose={() => setOpenMobileFilters(false)}
      />
    </>
  )
}

export default DashboardPage
