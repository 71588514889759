import { FC, useState } from "react"
import cx from "classnames"
import Checkbox from "@/components/Checkbox"
import InboxListItem from "../InboxListItem"
import EmptyList from "@/components/EmptyList"
import DeleteInboxModal from "../DeleteInboxModal"
import DeleteIcon from "@/components/icons/DeleteIcon"
import Loader from "@/components/Loader"
import { filterInboxesByClassification, sortInboxes } from "./utils"
import { useInboxes } from "../../hooks/useInboxes"
import { useInboxFilters } from "../../hooks/useInboxFilters"
import { useUserAccess } from "@/hooks/useUserAccess"
import { getDisplayClassification } from "../../utils"
import { useUpdateInbox } from "../../hooks/useUpdateInbox"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
  inboxes: Inbox[]
  selectedInboxId: string
  onInboxSelect: (inbox: Inbox) => void
}

const InboxList: FC<Props> = ({
  className,
  inboxes,
  selectedInboxId,
  onInboxSelect,
}: Props) => {
  const [selectedInboxes, setSelectedInboxes] = useState<string[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { isFetching, total, rootRef, sentryRef } = useInboxes()
  const { sort, filters } = useInboxFilters()
  const { pageFullAccess } = useUserAccess({ pageName: "Inbox" })
  const { updateInbox } = useUpdateInbox()

  const handleAllCheck = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedInboxes(checked ? inboxes.map(({ _id }) => _id) : [])

  const handleSelectedInboxChange = ({ _id }: Inbox, checked: boolean) =>
    setSelectedInboxes((prev) =>
      checked ? prev.concat(_id) : prev.filter((v) => v !== _id),
    )
  const handleMarkAsRead = async () => {
    const markedInboxes = inboxes.filter(
      ({ _id, readAt }) =>
        !readAt &&
        selectedInboxes.find((selectedInbox) => selectedInbox === _id),
    )
    if (!markedInboxes.length) {
      return
    }
    await updateInbox({ id: markedInboxes.map(({ _id }) => _id), read: true })
  }
  const inboxesPresent = inboxes.length > 0

  return (
    <>
      <div
        className={cx(
          "flex p-3 overflow-y-auto",
          {
            "justify-center items-center pb-16": !inboxes.length,
            "flex-col": inboxes.length,
          },
          className,
        )}
        ref={rootRef}
      >
        {inboxesPresent || isFetching ? (
          <>
            {pageFullAccess && (
              <div className="ml-3 flex items-center mb-2">
                {inboxesPresent && (
                  <Checkbox
                    checked={selectedInboxes.length === inboxes.length}
                    indeterminate={Boolean(
                      selectedInboxes.length > 0 &&
                        selectedInboxes.length < total,
                    )}
                    onChange={handleAllCheck}
                    id="inboxes-all"
                    className="checkbox-primary w-[18px] h-[18px]"
                  />
                )}
                <div className="flex justify-between gap-1 w-full">
                  {inboxesPresent && (
                    <label
                      htmlFor="inboxes-all"
                      className="cursor-pointer text-xs leading-5 opacity-60 pl-3"
                    >
                      {selectedInboxes.length} Selected
                    </label>
                  )}
                  {selectedInboxes.length > 0 && inboxesPresent && (
                    <div className="flex gap-1 items-center">
                      <span
                        onClick={handleMarkAsRead}
                        className="cursor-pointer text-xs text-primary hover:underline"
                      >
                        Mark as read
                      </span>
                      <DeleteIcon
                        onClick={() => setOpenDeleteModal(true)}
                        className="cursor-pointer w-5 h-5 opacity-60"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {inboxes
              .filter((inbox) => filterInboxesByClassification(inbox, filters))
              .filter((inbox) =>
                pageFullAccess
                  ? true
                  : !getDisplayClassification(inbox)
                      .toLowerCase()
                      .includes("hostile"),
              )
              .sort((a, b) => sortInboxes(a, b, sort))
              .map((inbox, i) => (
                <InboxListItem
                  key={i}
                  inbox={inbox}
                  selected={Boolean(
                    selectedInboxes.find(
                      (selectedId) => selectedId === inbox._id,
                    ),
                  )}
                  active={selectedInboxId === inbox._id}
                  onItemSelect={onInboxSelect}
                  onCheckboxChange={handleSelectedInboxChange}
                />
              ))}
            <div ref={sentryRef} />
            {isFetching && (
              <div className="flex justify-center">
                <Loader />
              </div>
            )}
          </>
        ) : (
          <EmptyList message="No messages" />
        )}
      </div>
      <DeleteInboxModal
        data={
          selectedInboxes.length
            ? inboxes.filter(({ _id }) =>
                selectedInboxes.find(
                  (selectedInboxId) => selectedInboxId === _id,
                ),
              )
            : []
        }
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  )
}

export default InboxList
