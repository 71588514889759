import { FC } from "react"
import cx from "classnames"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import AddIcon from "@/components/icons/AddIcon"
import Loader from "@/components/Loader"
import { Link } from "react-router-dom"
import SequenceListItem from "../SequenceListItem"
import { useInfiniteScrollSequences } from "../../hooks/useInfiniteScrollSequences"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { SequenceAPIResponse } from "@/types/entities/sequence"

type Props = {
  className?: string
  sequences: SequenceAPIResponse[]
  selectedSequenceId: string
  onSequenceSelect: (sequence: SequenceAPIResponse) => void
}

const SequenceList: FC<Props> = ({
  className,
  selectedSequenceId,
  onSequenceSelect,
  sequences,
}: Props) => {
  const { isFetching, rootRef, sentryRef } = useInfiniteScrollSequences()
  const { pageFullAccess } = useUserAccess({ pageName: "Sequences" })
  return (
    <div
      className={cx(
        "flex p-3 overflow-y-auto",
        {
          "justify-center items-center pb-16": !sequences.length,
          "flex-col": sequences.length,
        },
        className,
      )}
      ref={rootRef}
    >
      {sequences.length ? (
        <>
          {[...sequences]
            .sort(
              ({ createdAt: a }, { createdAt: b }) =>
                Number(new Date(b)) - Number(new Date(a)),
            )
            .map((sequence) => (
              <SequenceListItem
                active={sequence._id === selectedSequenceId}
                sequence={sequence}
                key={sequence._id}
                onClick={(sequence) => onSequenceSelect(sequence)}
              />
            ))}
          <div ref={sentryRef} />
          {isFetching && (
            <div className="flex justify-center">
              <Loader />
            </div>
          )}
        </>
      ) : (
        <EmptyList message="No sequences">
          {pageFullAccess && (
            <Link to="create">
              <Button className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary">
                <AddIcon className="transition-colors text-primary group-hover:text-white" />
                <span className="transition-colors font-medium text-primary group-hover:text-white">
                  Add Sequence
                </span>
              </Button>
            </Link>
          )}
        </EmptyList>
      )}
    </div>
  )
}

export default SequenceList
