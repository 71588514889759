import { FC, useState } from "react"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import Avatar from "@/components/Avatar"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import validationSchema from "./validationSchema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import ResetPasswordModal from "./features/ResetPasswordModal"
import ErrorMessage from "@/components/ErrorMessage"
import Tooltip from "@/components/Tooltip"
import WarningIcon from "@/components/icons/WarningIcon"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { RiCloseCircleLine } from "react-icons/ri"
import { useProfilePicture } from "../../hooks/useProfilePicture"
import { useGetCurrentUserQuery } from "@/core/api/services"
import type { UpdateUserShape } from "@/types/entities/user"
import AlertMessage from "@/components/AlertMessage"

const UserSettingsPage: FC = () => {
  const { data: user, isError } = useGetCurrentUserQuery()
  const navigate = useNavigate()
  const { updateUser, response } = useUpdateUser()
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const { mediumPicture } = useProfilePicture()
  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    dirty,
    errors: {
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
    },
    touched: {
      firstName: firstNameTouched,
      lastName: lastNameTouched,
      email: emailTouched,
    },
  } = useFormik<Omit<UpdateUserShape, "removePendingEmail">>({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
      profileImage: mediumPicture,
    },
    validationSchema,
    onSubmit: ({ firstName, lastName, email, profileImage }) => {
      const payload: UpdateUserShape = {
        firstName,
        lastName,
        email,
      }
      if (typeof profileImage === "object") {
        payload.profileImage = profileImage
      }
      updateUser(payload)
    },
  })
  const [previewPicture, setPreviewPicture] = useState<
    string | null | undefined
  >(undefined)

  const removeProfilePicture = (e: MouseEvent) => {
    e.preventDefault()
    setPreviewPicture(null)
    setFieldValue("profileImage", null)
  }
  const cancelPendingEmail = async () => {
    await updateUser({ removePendingEmail: true })
  }

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="mt-6 flex flex-col gap-5 max-w-2xl mx-auto"
      >
        <h2 className="text-2xl font-semibold">User Settings</h2>
        <div className="self-center">
          <input
            type="file"
            id="profile-image"
            className="w-0 h-0"
            accept="image/png, image/gif, image/jpeg"
            onChange={async ({ target }) => {
              if (!target.files?.length) {
                return
              }
              const file = target.files[0]
              const fileReader = new FileReader()
              fileReader.onload = ({ target }) => {
                setPreviewPicture(target?.result as string)
                setFieldValue("profileImage", file)
              }
              fileReader.readAsDataURL(file)
            }}
          />
          <label
            className="relative z-10 group cursor-pointer flex flex-col gap-2 items-center"
            htmlFor="profile-image"
          >
            <RiCloseCircleLine
              onClick={() => removeProfilePicture}
              className="absolute z-30 opacity-0 group-hover:opacity-100 transition-opacity top-0 right-0 hover:text-error"
            />
            <Avatar
              src={
                previewPicture !== undefined ? previewPicture : mediumPicture
              }
              className="w-[88px] h-[88px]"
            >
              <span className="font-semibold opacity-60 text-[32px]">
                {user?.firstName.slice(0, 1)}
                {user?.lastName.slice(0, 1)}
              </span>
            </Avatar>
            <span className="text-primary text-sm font-medium">
              Change Photo
            </span>
          </label>
        </div>
        <div className="flex gap-4 mt-2">
          <TextField
            topLeftLabel="First Name"
            className="flex-1"
            placeholder="Enter First Name"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={firstNameError}
                touched={firstNameTouched}
              />
            }
            autoComplete="off"
            {...getFieldProps("firstName")}
          />
          <TextField
            topLeftLabel="Last Name"
            className="flex-1"
            placeholder="Enter Last Name"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={lastNameError}
                touched={lastNameTouched}
              />
            }
            autoComplete="off"
            {...getFieldProps("lastName")}
          />
        </div>
        <TextField
          type="email"
          topLeftLabel={
            <div className="w-full flex justify-between items-center">
              <span>Email Address</span>
            </div>
          }
          bottomRightLabel={
            <>
              {user?.pendingEmail && (
                <AlertMessage className="bg-warning mt-4">
                  <WarningIcon className="w-4 h-4" />
                  Pending confirmation:
                  <span className="italic">{user.pendingEmail}</span>
                  <span>-</span>
                  <span className="flex items-center">
                    <Tooltip title="Cancel Email Update">
                      <span
                        className="hover:underline cursor-pointer font-medium"
                        onClick={cancelPendingEmail}
                      >
                        Cancel
                      </span>
                    </Tooltip>
                  </span>
                </AlertMessage>
              )}
            </>
          }
          className="w-full"
          placeholder="email@mail.com"
          bottomLeftLabel={
            <FormFieldErrorMessage error={emailError} touched={emailTouched} />
          }
          {...getFieldProps("email")}
        />
        <OutlineButton
          onClick={() => setOpenResetPasswordModal(true)}
          type="button"
          className="btn-sm h-10 py-1 text-black inline-block !w-40"
        >
          Change Password
        </OutlineButton>
        <div className="mt-2 pt-5 border-t border-t-outline flex justify-end gap-4">
          <OutlineButton
            disabled={response.isLoading}
            onClick={() => navigate(-1)}
            type="button"
            className="btn-sm py-1 text-black inline-block min-w-[86px]"
          >
            Cancel
          </OutlineButton>
          <Button
            loading={response.isLoading}
            disabled={response.isLoading || !dirty}
            type="submit"
            className="btn-primary btn-sm min-w-[96px]"
          >
            Save
          </Button>
        </div>
      </form>
      <ResetPasswordModal
        open={openResetPasswordModal}
        onClose={() => setOpenResetPasswordModal(false)}
      />
    </>
  )
}

export default UserSettingsPage
